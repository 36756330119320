import React, { PropsWithChildren, useEffect, useState } from 'react';

import useIsMounted from '@/hooks/useIsMounted';

export interface ConditionallyRenderProps {
  client?: boolean;
  server?: boolean;
}

const ConditionallyRender: React.FC<PropsWithChildren<ConditionallyRenderProps>> = (props) => {
  const isMounted = useIsMounted();

  if (!isMounted && props.client) {
    return null;
  }

  if (isMounted && props.server) {
    return null;
  }

  return props.children as React.ReactElement;
};

export default ConditionallyRender;
