import type { PropsWithChildren } from 'react';

import { ChakraProvider, color, extendTheme, getToken, cssVar } from '@chakra-ui/react';

const getColors = (props: any) => {
  const shadow = getToken('colors', `${props.colorScheme}.500`)(props.theme).replace('1)', '0.75)');
  const current = getToken('colors', `${props.colorScheme}.500`)(props.theme);
  const gradient = getToken('colors', `${props.colorScheme}.gradient`, '')(props.theme);
  const darken = getToken('colors', `${props.colorScheme}.700`)(props.theme);
  const gradients = props.colorScheme === 'magic' ? [current, gradient] : [gradient, current];
  return { shadow, current, gradients, gradient, darken };
};

const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false
  },
  styles: {
    global: {
      html: {
        backgroundColor: '#111',
        backgroundImage: "url('/static/bg/dark.jpg'), linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, #000000 100%)",
        backgroundRepeat: 'repeat-y',
        backgroundSize: '100% auto'
      },
      body: {
        background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, #000000 100%) !important',
        minHeight: '100vh',
        fontFamily: 'Poppins, sans-serif',
        color: '#fff'
      }
    }
  },
  fonts: {
    body: 'Poppins, sans-serif'
  },
  colors: {
    brand: {
      100: 'rgba(217, 255, 248, 1)',
      400: 'rgba(132, 191, 181, 1)',
      500: 'rgba(54, 250, 215, 1)',
      600: 'rgba(84, 149, 140, 1)',
      700: 'rgba(32, 91, 82, 1)',
      900: 'rgba(0, 35, 33, 1)',
      gradient: 'rgba(54, 250, 250, 1)'
    },

    // deprecated.
    primary: 'rgba(54, 250, 215, 1)',
    secondary: 'rgba(84, 149, 140, 1)',

    // Rarities
    legendary: {
      500: 'rgba(248, 136, 27, 1)',
      600: 'rgba(149, 117, 84, 1)',
      700: 'rgba(91, 62, 32, 1)',
      900: 'rgba(35, 18, 0, 1)',
      gradient: 'rgba(248, 80, 27, 1)'
    },

    magic: {
      500: 'rgba(137, 27, 248, 1)',
      600: 'rgba(117, 84, 149, 1)',
      700: 'rgba(62, 32, 91, 1)',
      900: 'rgba(18, 0, 35, 1)',
      gradient: 'rgba(217, 27, 248, 1)'
    },

    prime: {
      500: 'rgba(54, 121, 250, 1)',
      600: 'rgba(84, 106, 149, 1)',
      700: 'rgba(32, 52, 91, 1)',
      900: 'rgba(0, 12, 35, 1)',
      gradient: 'rgba(53, 81, 250, 1)'
    },

    // Grey
    grey: {
      950: '#0B0B0D',
      900: '#18181B',
      700: '#3F3F46',
      500: '#71717A',
      300: '#D4D4D8',
      100: '#F4F4F5'
    },

    // Cool Gary
    coolgary: {
      950: 'rgba(11, 13, 16, 0.75)',
      900: 'rgba(22, 27, 31, 0.8)',
      800: 'rgba(45, 50, 60, 1)'
    }
  },
  //
  // https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/anatomy/src/components.ts
  //
  components: {
    Button: {
      baseStyle: {
        fontWeight: '500',
        fontSize: 'sm',
        borderRadius: '2px',
        transition: 'all 0.25s ease-in-out'
      },
      variants: {
        primary: (props: any) => {
          const colors = getColors(props);
          const textColor = props.colorScheme === 'grey' ? '#fff' : '#181818';
          return {
            background: `linear-gradient(90deg, ${colors.gradients[0]} 0%, ${colors.gradients[1]} 100%)`,
            color: textColor,
            _hover: {
              background: `linear-graident(90deg, ${colors.gradients[0]} 0%, ${colors.gradients[1]} 100%)`,
              boxShadow: `0px 0px 20px ${colors.shadow}`
            },
            _active: {
              background: '#18181B',
              color: colors.current,
              boxShadow: 'none'
            },
            _disabled: {
              background: 'grey.700',
              color: 'grey.300',
              opacity: 1,
              boxShadow: 'none',
              border: '1px solid transparent',
              _hover: {
                border: `1px solid ${colors.current.replace('1)', '0.5)')}`,
                boxShadow: 'none'
              },
              _active: {
                boxShadow: 'none'
              }
            }
          };
        },
        secondary: (props: any) => {
          const colors = getColors(props);
          return {
            background: '#181818',
            color: '#fff',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: colors.current,
            _hover: {
              background: colors.darken,
              borderColor: colors.current
            },
            _active: {
              background: `linear-gradient(90deg, ${colors.gradients[0]} 0%, ${colors.gradients[1]} 100%)`,
              color: '#181818',
              borderColor: `linear-gradient(90deg, ${colors.gradients[0]} 0%, ${colors.gradients[1]} 100%)`
            },
            _disabled: {
              background: 'grey.700',
              color: 'grey.300',
              opacity: 1,
              borderColor: colors.current.replace('1)', '0.75)')
            }
          };
        },
        thirdly: (props: any) => {
          const colors = getColors(props);
          return {
            background: 'grey.700',
            color: '#fff',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'grey.700',
            _hover: {
              background: colors.darken,
              borderColor: colors.current
            },
            _active: {
              background: `linear-gradient(90deg, ${colors.gradients[0]} 0%, ${colors.gradients[1]} 100%)`,
              color: '#181818',
              borderColor: `linear-gradient(90deg, ${colors.gradients[0]} 0%, ${colors.gradients[1]} 100%)`
            },
            _disabled: {
              background: 'grey.700',
              color: 'grey.300',
              opacity: 1,
              borderColor: colors.current.replace('1)', '0.75)')
            }
          };
        },
        topnav: (props: any) => {
          const colors = getColors(props);
          return {
            textTransform: 'uppercase',
            borderBottom: '2px solid transparent',
            color: 'white',
            padding: '12px 24px',
            _hover: {
              backgroundColor: `${props.colorScheme}.600`,
              borderColor: colors.current,
              boxShadow: `0px 0px 20px ${colors.shadow}`
            },
            _active: {
              backgroundColor: colors.current,
              color: 'black'
            }
          };
        },
        topnavActive: (props: any) => {
          const colors = getColors(props);
          return {
            textTransform: 'uppercase',
            borderBottom: '2px solid transparent',
            borderColor: colors.current,
            color: colors.current,
            padding: '12px 24px',
            _hover: {
              backgroundColor: `${props.colorScheme}.600`,
              color: 'white',
              boxShadow: `0px 0px 20px ${props.shadow}`
            },
            _active: {
              backgroundColor: colors.current,
              color: 'black'
            }
          };
        },
        capsule: (props: any) => {
          const colors = getColors(props);
          return {
            textTransform: 'uppercase',
            border: '2px solid transparent',
            background: 'rgba(17, 17, 17, 0.75)',
            borderColor: colors.current,
            borderRadius: '6rem',
            color: colors.current,
            padding: '12px 2.5rem',
            lineHeight: '100%',
            _hover: {
              backgroundColor: color.current,
              boxShadow: `0px 0px 20px ${colors.shadow}`
            },
            _active: {
              backgroundColor: 'brand.600',
              color: 'black'
            }
          };
        },
        menuitem: (props: any) => {
          const colors = getColors(props);
          return {
            textAlign: 'left',
            _hover: {
              background: colors.current,
              color: 'black',
              boxShadow: `0px 0px 20px ${colors.shadow}`
            },
            _disabled: {
              _hover: {
                color: 'gray.400'
              }
            }
          };
        },
        specialA: (props: any) => {
          const colors = getColors(props);
          return {
            background: `linear-gradient(90deg, ${colors.gradients[0]} 0%, ${colors.gradients[1]} 100%)`,
            color: '#181818',
            position: 'relative',
            borderRadius: '2px',
            zIndex: '2',
            _before: {
              content: '""',
              background: `linear-gradient(90deg, ${colors.gradients[0]} 0%, ${colors.gradients[1]} 100%)`,
              position: 'absolute',
              top: '0',
              left: '0',
              right: '0',
              bottom: '0',
              zIndex: '-1',
              borderRadius: '2px',
              transform: 'perspective(0.4em) rotateX(-0.5deg)',
              transformOrigin: 'bottom left'
            },
            _hover: {
              background: `linear-gradient(90deg, ${colors.gradients[0]} 0%, ${colors.gradients[1]} 100%)`,
              boxShadow: `0px 0px 20px ${colors.shadow}`,
              _before: {
                boxShadow: `0px 0px 20px ${colors.shadow}`
              }
            },
            _active: {
              background: '#18181B',
              boxShadow: 'none',
              color: colors.current,
              _before: {
                background: '#18181B',
                boxShadow: 'none'
              }
            },
            _disabled: {
              background: 'grey.700',
              boxShadow: 'none',
              color: 'grey.300',
              opacity: 1,
              _before: {
                background: 'grey.700',
                boxShadow: 'none'
              },
              _hover: {
                boxShadow: 'none',
                _before: {
                  background: 'grey.700',
                  boxShadow: 'none'
                }
              },
              _active: {
                boxShadow: 'none',
                _before: {
                  background: 'grey.700',
                  boxShadow: 'none'
                }
              }
            }
          };
        },
        specialB: (props: any) => {
          const colors = getColors(props);
          return {
            background: `linear-gradient(90deg, ${colors.gradients[0]} 0%, ${colors.gradients[1]} 100%)`,
            color: '#181818',
            position: 'relative',
            left: '1.2rem',
            borderRadius: '2px',
            zIndex: '2',
            _before: {
              content: '""',
              background: `linear-gradient(90deg, ${colors.gradients[0]} 0%, ${colors.gradients[1]} 100%)`,
              position: 'absolute',
              top: '0',
              left: '0',
              right: '0',
              bottom: '0',
              zIndex: '-1',
              borderRadius: '2px',
              transform: 'scale(1.1) translateX(0.25rem) perspective(0.5em) rotateX(0.5deg)',
              transformOrigin: 'bottom right'
            },
            _hover: {
              background: `linear-gradient(90deg, ${colors.gradients[0]} 0%, ${colors.gradients[1]} 100%)`,
              boxShadow: `0px 0px 20px ${colors.shadow}`,
              _before: {
                boxShadow: `0px 0px 20px ${colors.shadow}`
              }
            },
            _active: {
              background: '#18181B',
              boxShadow: 'none',
              color: '#36FAD7',
              _before: {
                background: '#18181B',
                boxShadow: 'none'
              }
            },
            _disabled: {
              background: 'grey.700',
              boxShadow: 'none',
              color: 'grey.300',
              opacity: 1,
              _before: {
                background: 'grey.700',
                boxShadow: 'none'
              },
              _hover: {
                boxShadow: 'none',
                _before: {
                  background: 'grey.700',
                  boxShadow: 'none'
                }
              },
              _active: {
                boxShadow: 'none',
                _before: {
                  background: 'grey.700',
                  boxShadow: 'none'
                }
              }
            }
          };
        }
      },
      sizes: {
        sm: {
          fontSize: 'xs',
          padding: '10px 20px'
        },
        md: {
          fontSize: '1rem',
          padding: '11px 24px'
        },
        lg: {
          fontSize: '1rem',
          padding: '14px 32px'
        }
      },
      defaultProps: {
        variant: 'primary',
        colorScheme: 'brand'
      }
    },
    // END: Button

    Modal: {
      variants: {
        default: (props: any) => {
          const colors = getColors(props);
          return {
            overlay: {
              backdropFilter: 'blur(10px)'
            },
            header: {
              background: 'rgba(22, 27, 31, 0.8)',
              fontSize: '1.25rem',
              fontWeight: '500',
              borderImageSlice: 1,
              textAlign: 'center',
              fontFamily: 'Orbitron, monospace',
              padding: '30px'
            },
            dialog: {
              maxWidth: {
                base: 'calc(100vw - 6rem)',
                xl: '78rem'
              },
              maxHeight: {
                base: 'calc(100vh - 6rem)'
              },
              overflowY: 'scroll',
              background: 'transaprent',
            },
            body: {
              background: 'rgba(22, 27, 31, 0.8)',
              padding: '0 30px'
            },
            footer: {
              background: 'rgba(22, 27, 31, 0.8)',
              padding: '30px'
            },
            closeButton: {
              color: `${props.colorScheme}.600`,
              borderRadius: '100%',
              border: '2px solid transparent',
              borderColor: `${props.colorScheme}.600`,
              width: '36px',
              height: '36px',
              right: '30px',
              top: '28px',
              _hover: {
                color: colors.current,
                borderColor: colors.current
              }
            }
          };
        }
      },
      defaultProps: {
        variant: 'default',
        colorScheme: 'brand'
      }
    },
    // END: Modal

    Tabs: {
      variants: {
        default: (props: any) => {
          const colors = getColors(props);
          return {
            tab: {
              color: 'white',
              background: '#000',
              _selected: {
                background: colors.current.replace('1)', '0.5)')
              }
            }
          };
        },
        line: (props: any) => {
          const colors = getColors(props);
          return {
            tab: {
              color: 'grey.500',
              padding: '0.625rem 1.25rem calc(0.625rem - 3px)',
              _selected: {
                borderBottom: '2px solid',
                color: colors.current
              }
            },
            tablist: {
              borderBottom: '1px solid',
              borderColor: '#2D323C',
              paddingBottom: '3px'
            },
            tabpanel: {
              padding: '1.25rem'
            }
          };
        }
      },
      defaultProps: {
        variant: 'default',
        colorScheme: 'brand'
      }
    },
    // END: Tabs

    Heading: {
      baseStyle: {
        fontFamily: 'Orbitron, monospace'
      }
    },
    // END: Heading

    Tag: {
      variants: {
        default: (props: any) => {
          const colors = getColors(props);
          const border = props.colorScheme === 'secondary' ? '1px solid #71717A' : `1px solid transparent`;
          const textColor = props.colorScheme === 'brand' ? '#181818' : '#fff';
          return {
            container: {
              background: `linear-gradient(90deg, ${colors.gradients[0]} 0%, ${colors.gradients[1]} 100%)`,
              borderRadius: '2px',
              // border,
              padding: '0.375rem 1rem',
              color: textColor,
              borderImageSlice: 1,
              borderWidth: '1px',
              borderImageSource: `linear-gradient(90deg, ${colors.gradients[0]} 0%, ${colors.gradients[1]} 100%)`
            }
          };
        }
      },
      defaultProps: {
        variant: 'default'
      }
    },
    // Tag

    Progress: {
      variants: {
        default: (props: any) => {
          const colors = getColors(props);
          const number = props.theme.components.Progress.sizes[props.size].track.h;
          const top1 = (number / 4) * 0.16;
          const top2 = (number / 4) * 0.85;
          const size = getToken('sizes', number)(props.theme);
          const left = getToken('sizes', number / 5)(props.theme);
          return {
            filledTrack: {
              backgroundColor: colors.current,
              position: 'relative',
              left: `-${left}`,
              _after: {
                content: "''",
                position: 'absolute',
                top: `-${top1}rem`,
                right: '0',
                borderRight: `${size} solid ${colors.current}`,
                borderTop: `${size} solid transparent`,
                borderBottom: `${size} solid transparent`,
                transform: 'rotate(45deg)'
              },
              _before: {
                content: "''",
                position: 'absolute',
                top: `-${top2}rem`,
                right: '0',
                borderRight: `${size} solid ${colors.current}`, // 蓝色是为了遮盖红色后比较容易调整位置
                borderTop: `${size} solid transparent`,
                borderBottom: `${size} solid transparent`,
                transform: 'rotate(-45deg)'
              }
            },
            track: {
              backgroundColor: 'rgba(11, 13, 16, 0.75)',
              borderRadius: '2px'
            }
          };
        }
      },
      sizes: {
        xl: {
          track: {
            h: 6
          }
        }
      },
      defaultProps: {
        variant: 'default',
        colorScheme: 'brand',
        size: 'lg'
      }
    },
    // Progress

    Select: {
      variants: {
        default: (props: any) => {
          const colors = getColors(props);
          return {
            field: {
              height: '2.5rem',
              borderImageSlice: 1,
              borderWidth: '1px',
              borderImageSource: `linear-gradient(90deg, ${colors.gradients[0]} 0%, ${colors.gradients[1]} 100%)`,
              borderRadius: '4px',
              color: 'white',
              backgroundColor: 'gray.800',
            }
          };
        }
      },
      defaultProps: {
        variant: 'default',
        colorScheme: 'brand'
      }
    },
    // Select

    Input: {
      variants: {
        default: (props: any) => {
          const colors = getColors(props);
          return {
            field: {
              height: '2.5rem',
              borderImageSlice: 1,
              borderWidth: '1px',
              borderImageSource: `linear-gradient(90deg, ${colors.gradients[0]} 0%, ${colors.gradients[1]} 100%)`,
              borderRadius: '4px'
            }
          };
        }
      },
      defaultProps: {
        variant: 'default',
        colorScheme: 'brand'
      }
    },
    // Input

    Popover: {
      baseStyle: (props: any) => {
        const colors = getColors(props);
        return {
          body: {
            padding: '0.625rem 1.25rem'
          },
          popper: {
            zIndex: 60
          },
          content: {
            background: colors.current,
            [cssVar('popper-arrow-bg').variable]: `var(--chakra-colors-${props.colorScheme}-500)`,
            width: 'auto',
            boxShadow: '0px 0px 20px #2D323C',
            borderRadius: 'sm'
          }
        };
      },
      variants: {
        skin: {
          header: {
            background: 'rgba(22, 27, 31, 0.8)',
            backdropFilter: 'blur(20px)',
            border: '0',
            padding: '6px 10px',
            fontSize: '14px',
          },
          body: {
            padding: '4px 10px',
          },
          content: {
            'background': 'rgba(11, 13, 16, 0.75)',
            backdropFilter: 'blur(20px)',
            // border: '1px solid #36FAD7',
            border: '0',
            filter: 'drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.8))',
          }
        }
      },
      defaultProps: {
        variant: 'default',
        colorScheme: 'brand'
      }
    },
    // Popover

    Checkbox: {
      variants: {
        default: (props: any) => {
          const colors = getColors(props);
          return {
            container: {
              flexDirection: 'row-reverse',
              justifyContent: 'space-between',
              flexGrow: 1,
            },
            label: {
              marginLeft: 0,
            },
            control: {
              width: '1rem',
              height: '1rem',
              borderRadius: '4px',
              borderWidth: '2px',
              borderColor: '#D9D9D9',
              backgroundColor: '#000',
              _checked: {
                borderColor: colors.current,
                backgroundColor: colors.current,
              }
            },
          }
        },
      },
      defaultProps: {
        variant: 'default',
        colorScheme: 'brand',
      },
    },
    // Checkbox

    Alert: {
      variants: {
        default: {
          container: {
            backgroundColor: 'brand.500',
            color: 'black',
            borderRadius: 'sm',
            w: 'auto',
          },
        },
      },
      defaultProps: {
        variant: 'default',
        colorScheme: 'brand',
      },
    },
    // Alert

    Tooltip: {
      variants: {
        default: (props: any) => {
          return {
            bg: 'brand.500',
            color: 'black',
            p: '2.5',
            [cssVar('popper-arrow-bg').variable]: `var(--chakra-colors-${props.colorScheme}-500)`,
          }
        },
      },
      defaultProps: {
        variant: 'default',
        colorScheme: 'brand',
      },
    },
    // Tooltip
  }
});

export default function ChakraThemeProvider({ children }: PropsWithChildren<{}>) {
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
}
