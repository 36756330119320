import type { WebsocketConnectionMachine, WebsocketConnectionContext } from './websocketConnectionMachine'

import { useEffect, useState } from 'react'
import { createMachine } from 'xstate'
import { atom, useAtom } from 'jotai'
import { atomWithReset, atomWithStorage } from 'jotai/utils'
import { atomWithMachine } from 'jotai/xstate'

import createLogger from '@/utils/createLooger'
import useIsMounted from '@/hooks/useIsMounted'

import { createWebsocketConnectionMachineConfig, createWebsocketConnectionMachineOptions } from './websocketConnectionMachine'
import create from './create'

const debug = createLogger('parachain', 'debug')

export const PhalaWorldTestNets = [
  'wss://node-dev.phala.world'
]

//
// Joshua's Testnet, need extra browser configuration for mixed connections
// 'ws://34.122.108.18:9944'
//
// jasl's Testnet
// 'wss://pc-test-3.phala.network/khala/ws'
export const CertificatedKhalaNetwork = [
  'wss://khala-api.phala.network/ws',
  'wss://khala.api.onfinality.io/public-ws',
  'wss://khala-rpc.dwellir.com/',
  'wss://kha.api.cardinate.io',
]

const preferTestnet = process.env.NEXT_PUBLIC_WS_ENDPOINT && process.env.NEXT_PUBLIC_WS_ENDPOINT !== 'OFFICIAL'

if (process.env.NEXT_PUBLIC_WS_ENDPOINT && process.env.NEXT_PUBLIC_WS_ENDPOINT !== 'OFFICIAL') {
  CertificatedKhalaNetwork.push(process.env.NEXT_PUBLIC_WS_ENDPOINT)
}

/**
 * 2022-10-25: Random pick a certicated network for end-user as onfinality is reported unstable from some users.
 */
export const PARACHAIN_ENDPOINT = (process.env.NEXT_PUBLIC_WS_ENDPOINT && process.env.NEXT_PUBLIC_WS_ENDPOINT !== 'OFFICIAL')
  ? process.env.NEXT_PUBLIC_WS_ENDPOINT
  : (
    process.env.NODE_ENV === 'production'
      ? CertificatedKhalaNetwork[Math.floor(Math.random() * CertificatedKhalaNetwork.length)]
      : PhalaWorldTestNets[0]
  )

export const endpointAtom = preferTestnet ? atomWithReset<string>(PARACHAIN_ENDPOINT) : atomWithStorage<string>('last-selected-atom', PARACHAIN_ENDPOINT)

export const websocketConnectionMachineAtom = atomWithMachine<WebsocketConnectionMachine>((get) => {
  const endpoint = get(endpointAtom)
  const ctx: WebsocketConnectionContext = {
    endpoint: endpoint,
  }
  debug(`network endpoint: ${endpoint}`)
  return createMachine(
    createWebsocketConnectionMachineConfig(ctx),
    createWebsocketConnectionMachineOptions({
      // Services
      services: {
        connect: (ctx, evt) => async (send) => {
          try {
            if (ctx.connection) {
              await ctx.connection.disconnect()
              // Because we will send RECONNECTING on disconnected, so we need wait 500ms ensure the event
              // populate sequence correctly.
              await new Promise(resolve => setTimeout(resolve, 500))
            }

            const seed = setTimeout(() => {
              send({ type: 'CONNECT_TIMEOUT', data: { error: new Error('Connect timeout.') } })
            }, 5 * 1000)

            const [ws, api] = await create(ctx.endpoint)
            debug('connected')

            ws.on('error', (err) => {
              // @TODO
              debug('setStatus -> error')
            })

            api.on('connected', async () => {
              await api.isReady
              debug('setStatus -> connected')
              send({
                type: 'CONNECTED',
                data: {
                  endpoint: ctx.endpoint,
                  connection: api,
                }
              })
            })
    
            api.on('disconnected', (evt) => {
              debug('setStatus -> disconnected', ctx, evt)
              send({ type: 'RECONNECTING' })
            })

            await api.isReady

            clearTimeout(seed)

            send({
              type: 'CONNECTED',
              data: {
                endpoint: ctx.endpoint,
                connection: api,
              }
            })
          } catch (err) {
            console.log('wtf', err)
          }
        },

        disconnect: (ctx) => async (send) => {
          await ctx.connection?.disconnect()
          send({ type: 'DISCONNECTED', data: { endpoint: ctx.endpoint } })
        },

        check_connection: (ctx) => (send) => {
          const start = +new Date()
          const seed = setInterval(() => {
            const now = +new Date()
            const timeout = 30 * 1000
            if ((now - start) > timeout) {
              send({ type: 'CONNECT_FAILED', data: { error: new Error('network offline.') } })
            }
          }, 500)
          return () => clearInterval(seed)
        }
      }
    })
  )
}, {
  devTools: process.env.NODE_ENV === 'development',
})

export const apiPromiseAtom = atom(async (get) => {
  while (true) {
    const machine = get(websocketConnectionMachineAtom)
    if (machine.value === 'connected') {
      return machine.context.connection!
    }
    await new Promise(resolve => setTimeout(resolve, 500))
  }
})

export const useAutoConnect = () => {
  const isMounted = useIsMounted()
  // NOTE: We can't use `useSetAtom` here. If we don't access the machine instance,
  // the connection will not be established.
  const [_, send] = useAtom(websocketConnectionMachineAtom)
  useEffect(() => {
    if (isMounted) {
      send({ type: 'CONNECT' })
    }
  }, [isMounted, send])
}
