import { useSetAtom, WritableAtom } from 'jotai';

import { useState, useCallback } from 'react';
import tw from 'twin.macro';
import { WsProvider, ApiPromise } from '@polkadot/api';
import * as R from 'ramda';
import * as TE from 'fp-ts/TaskEither';
import * as E from 'fp-ts/Either';
import { FaRegCircle, FaRegDotCircle } from 'react-icons/fa';

import { CertificatedKhalaNetwork, endpointAtom, websocketConnectionMachineAtom } from '@/features/parachain/atoms';

import { useAtom } from 'jotai';
import {
  Button,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton
} from '@chakra-ui/react';

type ConnectivityResult = E.Either<any, number>;
type ConnectivityResultSet = Record<string, ConnectivityResult>;

async function safeConnect(endpoint: string) {
  const provider = new WsProvider(endpoint);
  const result: E.Either<any, ApiPromise> = await Promise.race([
    TE.tryCatch(() => ApiPromise.create({ provider }), E.toError)(),
    new Promise((resolve) => setTimeout(() => resolve(E.left('Connect Tiemout')), 5000)) as Promise<
      E.Either<any, ApiPromise>
    >
  ]);
  if (E.isLeft(result)) {
    provider.disconnect();
  }
  return result;
}

async function safeGetEndpointLatency(endpoint: string) {
  const started = Date.now();
  const result = await safeConnect(endpoint);
  if (E.isRight(result)) {
    await result.right.query.timestamp.now();
    await result.right.disconnect();
    return E.right(Date.now() - started);
  }
  return result;
}

function useTestEndpoint() {
  const [isTesting, setIsTesting] = useState(false);
  const [result, setResult] = useState<ConnectivityResultSet>({});
  const testAllEndpoints = useCallback(async () => {
    setIsTesting(true);
    const result = await Promise.all(CertificatedKhalaNetwork.map(safeGetEndpointLatency));
    setResult(R.zipObj(CertificatedKhalaNetwork, result));
    setIsTesting(false);
  }, [setIsTesting, setResult]);

  return [isTesting, result, testAllEndpoints] as [boolean, Readonly<ConnectivityResultSet>, () => void];
}

const Tag = tw.span`px-4 py-0.5 text-xs text-white font-secondary`;

const ConnectivityLabel = ({ result }: { result?: ConnectivityResult }) => {
  if (result) {
    if (E.isRight(result)) {
      return <Tag tw="bg-green-500">{result.right} ms</Tag>;
    }
    if (E.isLeft(result)) {
      return <Tag tw="bg-red-500">Failed</Tag>;
    }
  }
  return null;
};

export const EndpointSelectModal = ({ visibleAtom }: { visibleAtom: WritableAtom<boolean, boolean> }) => {
  const [visible, setVisible] = useAtom(visibleAtom);
  const [isTesting, result, runTest] = useTestEndpoint();
  const [currentEndpoint, switchEndpoint] = useAtom(endpointAtom);
  const send = useSetAtom(websocketConnectionMachineAtom);
  return (
    <Modal isOpen={visible} onClose={() => setVisible(false)} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent tw="xl:min-w-[540px]">
        <ModalHeader>Select Endpoint</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <div tw="flex flex-col gap-4">
            <div tw="flex flex-row justify-end px-4">
              <Button size="sm" onClick={runTest} isLoading={isTesting}>
                Test
              </Button>
            </div>
            <hr />
            <div tw="flex flex-col gap-0.5">
              {CertificatedKhalaNetwork.map((endpoint) => (
                <div
                  key={endpoint}
                  tw="flex flex-row justify-between items-center px-4 py-2.5 rounded-sm cursor-pointer hover:bg-brand-700"
                  onClick={() => {
                    switchEndpoint(endpoint);
                    send({ type: 'RECONNECT', data: { endpoint } });
                  }}
                >
                  <div tw="flex flex-row gap-2 items-center">
                    {endpoint === currentEndpoint ? (
                      <FaRegDotCircle tw="h-2.5 w-2.5 text-brand-500" />
                    ) : (
                      <FaRegCircle tw="h-2.5 w-2.5 text-gray-500" />
                    )}
                    <code tw="text-sm relative">{endpoint}</code>
                  </div>
                  {isTesting ? (
                    <Spinner size="xs" color="brand.500" />
                  ) : (
                    <ConnectivityLabel result={result[endpoint]} />
                  )}
                </div>
              ))}
            </div>
          </div>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};
