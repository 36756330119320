import type { ReactNode } from 'react';

import { Suspense, useCallback, useMemo } from 'react';
import tw, { styled } from 'twin.macro';
import Link from 'next/link';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { Menu, MenuButton, MenuList, MenuItem, Button, ButtonGroup, useDisclosure } from '@chakra-ui/react';
import { BsThreeDots } from 'react-icons/bs';

import {
  currentAccountAtom,
  lastSelectedWeb3ProviderAtom,
  useRestoreLastSelectedAccount
} from '@/features/identity/atoms';
import AccessPointCombo from '@/features/identity/components/AccessPointCombo';
import ConditionallyRender from './ConditionallyRender';
import { WalletSelectModal, AccountSelectModal } from '@/features/identity/components/AccountSelectModal';
import { useAutoConnect } from '@/features/parachain/atoms';
import { EndpointSelectModal } from './EndpointSelectModal';
import Logo from '../svgs/logo.svg';
import { useRouter } from 'next/router';

export const walletSelectModalVisibleAtom = atom(false);

export const accountSelectModalVisibleAtom = atom(false);

export const endpointSelectModalVisibleAtom = atom(false);

export const useShowAccountSelectModal = () => {
  const setWalletSelectModalVisible = useSetAtom(walletSelectModalVisibleAtom);
  const setAccountSelectModalVisible = useSetAtom(accountSelectModalVisibleAtom);
  const currentProvider = useAtomValue(lastSelectedWeb3ProviderAtom);
  return useCallback(() => {
    if (currentProvider) {
      setAccountSelectModalVisible(true);
    } else {
      setWalletSelectModalVisible(true);
    }
  }, [setWalletSelectModalVisible, setAccountSelectModalVisible, currentProvider]);
};

const StyledLogo = styled(Logo)`
  ${tw`text-white flex items-center h-8`}

  @media (max-width: 1440px) {
    .title {
      display: none;
    }
  }
`;

const ProfileButton = ({ isActive }: { isActive?: boolean }) => {
  const currentAccount = useAtomValue(currentAccountAtom);
  if (!currentAccount) {
    return null;
  }
  return (
    <Link
      href={`/profiles/${currentAccount.address}`}
      passHref
      shallow
      legacyBehavior
    >
      <Button as="a" variant="topnav" isActive={isActive}>
        PROFILE
      </Button>
    </Link>
  );
};

function HoverDropdownMenu({ title, children, isActive }: { title: string, isActive?: boolean, children: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Menu isOpen={isOpen}>
      <MenuButton
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
      >
        <Button
          as="a"
          variant="topnav"
          isActive={isActive}
          backgroundColor={isOpen ? 'brand.600': undefined}
          borderColor={isOpen ? 'brand.500' : undefined}
          boxShadow={isOpen ? '0px 0px 20px rgba(54, 250, 215, 0.75)' : undefined}
        >
          {title}
        </Button>
      </MenuButton>
      <MenuList bg="rgba(22, 27, 31, 0.8)" borderWidth="0" py="0" onMouseEnter={onOpen} onMouseLeave={onClose}>
        {children}
      </MenuList>
    </Menu>
  )
}

// @FIXME: active by current path.
// default variant = topnav , switch to topnavActive when active
const NavItems = () => {
  const router = useRouter()
  const { isHome, isLearn, isShop, isPlay, isProfile } = useMemo(() => ({
    isHome: router.pathname === '/',
    isLearn: router.pathname.startsWith('/history') || router.pathname.startsWith('/docs'),
    isShop: (
      router.pathname.startsWith('/claim') || router.pathname.startsWith('/rarities') || router.pathname.startsWith('/mint') || router.pathname.startsWith('/marketplace')
      || router.pathname.startsWith('/collections')
    ),
    isPlay: router.pathname.startsWith('/leaderboards'),
    isProfile: router.pathname.startsWith('/profiles')
  }), [router])
  return (
    <ButtonGroup gap="3">
      <Link href="/" passHref shallow legacyBehavior>
        <Button as="a" variant="topnav" isActive={isHome}>
          Home
        </Button>
      </Link>
      <HoverDropdownMenu title="LEARN" isActive={isLearn}>
        <Link href="/history" passHref shallow legacyBehavior>
          <MenuItem as="a" _hover={{ color: 'black', background: 'brand.500' }}>
            Museum
          </MenuItem>
        </Link>
        <Link href="/docs" passHref shallow legacyBehavior>
          <MenuItem as="a" _hover={{ color: 'black', background: 'brand.500' }}>
            Docs
          </MenuItem>
        </Link>
      </HoverDropdownMenu>
      <HoverDropdownMenu title="SHOP" isActive={isShop}>
        <Link href="/claim" passHref shallow legacyBehavior>
          <MenuItem as="a" _hover={{ color: 'black', background: 'brand.500' }}>
            Claim Spirit
          </MenuItem>
        </Link>
        <Link href="/rarities" passHref shallow legacyBehavior>
          <MenuItem as="a" _hover={{ color: 'black', background: 'brand.500' }}>
            Mint Shell
          </MenuItem>
        </Link>
        <Link href="/marketplace" passHref shallow legacyBehavior>
          <MenuItem as="a" _hover={{ color: 'black', background: 'brand.500' }}>
            Marketplace
          </MenuItem>
        </Link>
      </HoverDropdownMenu>
      <HoverDropdownMenu title="PLAY" isActive={isPlay}>
        <Link href="/leaderboards/spirit" passHref shallow legacyBehavior>
          <MenuItem as="a" _hover={{ color: 'black', background: 'brand.500' }}>
            Spirit Leaderboard
          </MenuItem>
        </Link>
        <Link href="/leaderboards/food" passHref shallow legacyBehavior>
          <MenuItem as="a" _hover={{ color: 'black', background: 'brand.500' }}>
            Food Leaderboard
          </MenuItem>
        </Link>
      </HoverDropdownMenu>
      <ConditionallyRender client>
        <Suspense fallback={null}>
          <ProfileButton isActive={isProfile} />
        </Suspense>
      </ConditionallyRender>
    </ButtonGroup>
  );
};

const PerferenceButton = () => {
  return (
    <div tw="border border-solid border-brand rounded-sm p-0.5">
      <Button h="2.2rem" borderRadius="sm" bg="gray.900" textColor="white" _hover={{ bg: 'brand.500', color: 'black' }}>
        <BsThreeDots tw="font-light text-xl" />
      </Button>
    </div>
  );
};

const RehrydrateAccessPointCombo = () => {
  useRestoreLastSelectedAccount();
  useAutoConnect();
  const showAccountSelectModal = useShowAccountSelectModal();
  const setEndpointModalVisible = useSetAtom(endpointSelectModalVisibleAtom);
  return (
    <AccessPointCombo onAccountClick={showAccountSelectModal} onDotClick={() => setEndpointModalVisible(true)} />
  )
}

export default function SiteNav() {
  return <>
    <header tw='max-w-10xl xl:mx-0 3xl:mx-auto py-6'>
      <div tw='grid grid-cols-12 gap-5 max-w-10xl px-5 xl:mx-0 3xl:mx-auto'>
        <div tw='col-span-12 xl:col-start-2 xl:col-span-10 3xl:col-start-2 3xl:col-span-10 flex justify-between'>
          <div tw='h-10 flex items-center cursor-pointer'>
            <Link href='/' as='a' tw='w-[66px] 2xl:w-auto overflow-hidden' legacyBehavior>
              <StyledLogo />
            </Link>
          </div>
          <div tw='hidden lg:block'>
            <NavItems />
          </div>
          <div tw="flex gap-2 max-h-10">
            <ConditionallyRender client>
              <Suspense>
                <RehrydrateAccessPointCombo /> 
              </Suspense>
            </ConditionallyRender>
            {/* <PreferenceButton /> */}
          </div>
        </div>
      </div>
    </header>
    <WalletSelectModal
      visibleAtom={walletSelectModalVisibleAtom}
      accountSelectModalVisibleAtom={accountSelectModalVisibleAtom}
    />
    <AccountSelectModal
      visibleAtom={accountSelectModalVisibleAtom}
      walletSelectModalVisibleAtom={walletSelectModalVisibleAtom}
    />
    <EndpointSelectModal visibleAtom={endpointSelectModalVisibleAtom} />
  </>;
}
